<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar"> <div class="loader"></div> </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile"><h3>Dashboard</h3></div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="dasboard-section">
                    <div class="profile-section mb-4">
                      <div class="row">
                        <div class="col-md-10  text-capitalize font-weight-bold pb-2">
                          profile :
                        </div>
                        <div
                          class="col-md-2  text-capitalize font-weight-bold text-right pb-2"
                          @click="showMore()"
                        >
                          view more
                        </div>
                      </div>

                      <div class="row">
                        <div
                          class="col-lg-4 col-md-4"
                          v-for="item in profileCount"
                          v-bind:key="item._id"
                        >
                          <div class="list-section">
                            <div class=" text-capitalize">{{ item.hotelName }}</div>
                            <div class=" text-capitalize">{{ item.views }}</div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-4"> <div class="list-section">{{}}</div> </div> <div class="col-lg-4 col-md-4"> <div class="list-section">{{}}</div> </div> -->
                      </div>
                    </div>
                    <div class="leads-section mb-5">
                      <div class="row">
                        <div class="col-md-10  text-capitalize font-weight-bold pb-2">
                          leads :
                        </div>
                        <div
                          class="col-md-2  text-capitalize font-weight-bold text-right pb-2"
                          @click="showMoreLeads()"
                        >
                          view more
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-lg-4 col-md-4"
                          v-for="item in leadCount"
                          v-bind:key="item._id"
                        >
                          <div class="list-section">
                            <div class=" text-capitalize">{{ item._id.name }}</div>
                            <div class=" text-capitalize">{{ item.count }}</div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-4"> <div class="list-section">1</div> </div> <div class="col-lg-4 col-md-4"> <div class="list-section">2</div> </div> <div class="col-lg-4 col-md-4"> <div class="list-section">3</div> </div> -->
                      </div>
                    </div>
                    <div class="row mb-5">
                      <div class="col-lg-3 col-md-3">
                        <router-link to="/shortlist">
                          <div class="list-section">
                            <div class=" text-capitalize">shortlisted properties</div>
                            <div class=" text-capitalize">{{ count.shortListed }}</div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <router-link to="/deals-offers">
                          <div class="list-section">
                            <div class=" text-capitalize">deals and offers</div>
                            <div class=" text-capitalize">{{ count.dealsNOffers }}</div>
                          </div>
                          <!-- <div>{{ count.dealsNOffers }}</div> -->
                        </router-link>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <router-link to="/viewUserList">
                          <div class="list-section">
                            <div class=" text-capitalize">no of users</div>
                            <div class=" text-capitalize">{{ count.noOfUsers }}</div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <router-link to="/viewHotelList">
                          <div class="list-section">
                            <div class=" text-capitalize">no of hotels</div>
                            <div class=" text-capitalize">{{ count.noOfHotels }}</div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col-lg-3 col-md-3">
                        <router-link to="/viewHotelList">
                          <div class="list-section">
                            <div class=" text-capitalize">no of Verified hotels</div>
                            <div class=" text-capitalize">{{ count.noOfVerifiedHotels }}</div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></template
>
<script>
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
export default {
  components: { headerCustom },
  data() {
    return { count: {}, profileCount: [], leadCount: [] };
  },
  created() {
    this.getTotalCountForAdmin();
    this.getProfileViewsCountForAdmin();
    this.getLeadCountForAdminDashboard();
  },
  methods: {
    getTotalCountForAdmin() {
      var obj = {};
      apiservice.getTotalCountForAdmin(obj, data => {
        this.count = data.data;
      });
    },
    getProfileViewsCountForAdmin() {
      var obj = {
        limit: 3
      };
      apiservice.getProfileViewsCountForAdmin(obj, data => {
        this.profileCount = data.data;
      });
    },
    getLeadCountForAdminDashboard() {
      var obj = {};
      apiservice.getLeadCountForAdminDashboard(obj, data => {
        this.leadCount = data.data;
      });
    },
    showMore() {
      this.$router.push({ name: "allProfileList" });
    },
    showMoreLeads() {
      this.$router.push({ name: "allLeadList" });
    }
  }
};
</script>
<style>
.list-section {
  height: 90px;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #735cf2, #958af4);
}
</style>
